import type { NotificationCampaign } from './types';

export function formatRecurring(
  notificationCampaign: NotificationCampaign | NotificationTemplate,
) {
  if (notificationCampaign.recurring_type === 'quantity_day') {
    if (notificationCampaign.recurring_interval === 1) {
      return `Co 1 dzień`;
    } else {
      return `Co ${notificationCampaign.recurring_interval} dni`;
    }
  } else if (notificationCampaign.recurring_type === 'choice_day') {
    const daysMap = [null, 'pn', 'wt', 'śr', 'czw', 'pt', 'sb', 'nd'];
    return `${notificationCampaign.recurring_days_of_week
      ?.map((day) => {
        return daysMap[day] + '.';
      })
      .join(', ')}`;
  }
}

export function formatDeliveryStatus(
  statusName: 'unprocessed' | 'success' | 'failed',
) {
  switch (statusName) {
    case 'unprocessed':
      return { type: 'info', text: 'W trakcie' };
    case 'success':
      return { type: 'success', text: 'Dostarczone' };
    case 'failed':
      return { type: 'warn', text: 'Błąd' };
    default:
      return { type: 'secondary', text: 'Brak statusu' };
  }
}
