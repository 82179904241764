export function useNotificationForms() {
  const formRefs = ref<any[]>([]);
  const forms = ref<
    {
      type: 'default' | 'form' | 'visit';
    }[]
  >([{ type: 'default' }]);

  async function changeType(type: 'default' | 'form' | 'visit', index: number) {
    const previousForm = formRefs.value[index]?.form;

    forms.value[index].type = type;

    await nextTick();

    formRefs.value[index].form.designation = previousForm?.designation;
    formRefs.value[index].form.title = previousForm?.title;
    formRefs.value[index].form.body = previousForm?.body;
  }

  function resetForms() {
    forms.value = [{ type: 'default' }];
    formRefs.value[0].resetForm();
  }

  function fillForm(notification: NotificationCampaign | NotificationTemplate) {
    formRefs.value[0].form.designation = notification.designation;
    formRefs.value[0].form.title = notification.title;
    formRefs.value[0].form.body = notification.body;
    formRefs.value[0].form.type = notification.type;

    if (notification.kind_type === 'default') {
      forms.value[0].type = 'default';

      formRefs.value[0].form.is_recurring = notification.is_recurring;
      formRefs.value[0].form.start_time = notification.start_time
        ? setTodayWithTime(notification.start_time)
        : notification.start_time;
      formRefs.value[0].form.send_for = notification.send_for;
      formRefs.value[0].form.start_date = notification.start_date
        ? new Date(notification.start_date)
        : undefined;

      if (notification.is_recurring) {
        formRefs.value[0].form.recurring_type = notification.recurring_type;

        if (notification.recurring_type === 'quantity_day') {
          formRefs.value[0].form.recurring_interval =
            notification.recurring_interval;
        }

        if (notification.recurring_type === 'choice_day') {
          formRefs.value[0].form.recurring_days_of_week =
            notification.recurring_days_of_week;
        }

        if (notification.recurring_type === 'calendar_days') {
          formRefs.value[0].form.recurring_calendar_days =
            notification.recurring_calendar_days;
        }

        if (notification.recurring_type !== 'calendar_days') {
          formRefs.value[0].form.end_date = notification.end_date
            ? new Date(notification.end_date)
            : undefined;
        }
      }

      if (notification.send_for === 'all') {
        formRefs.value[0].form.schemat_id = notification.schemat_id;
      } else {
        formRefs.value[0].form.send_user_ids = notification.send_user_ids;
      }
    } else if (notification.kind_type === 'form') {
      forms.value[0].type = 'form';
      formRefs.value[0].form.form_id = notification.form_id;
    } else if (notification.kind_type === 'visit') {
      forms.value[0].type = 'visit';
      formRefs.value[0].form.schemat_id = notification.schemat_id;
      formRefs.value[0].form.quantity_days = notification.quantity_days;
    }
  }

  return {
    formRefs,
    forms,
    changeType,
    resetForms,
    fillForm,
  };
}
