<script setup lang="ts">
const {
  data: forms,
  search,
  page,
  isLoading,
  per_page,
  first,
} = useFormTemplates();

const { confirmDelete } = useDeleteFormTemplate();

const pageUpdate = (newPage) => {
  page.value = newPage.page;
};
</script>

<template>
  <div>
    <div class="mb-6 flex justify-between">
      <div class="flex gap-4">
        <PrimeIconField v-model="search">
          <PrimeInputIcon class="pi pi-search" />
          <PrimeInputText v-model="search" placeholder="Szukaj" />
        </PrimeIconField>
      </div>
      <AddForm />
    </div>
    <template v-if="forms?.data?.length || isLoading">
      <PrimeDataTable
        class="primetable"
        :value="forms?.data"
        tableStyle="min-width: 50rem"
        dataKey="id"
      >
        <PrimeColumn field="name" header="Nazwa"></PrimeColumn>
        <PrimeColumn field="description" header="Opis"></PrimeColumn>
        <PrimeColumn field="form_questions" header="Ilość pytań">
          <template #body="slotProps">
            {{ slotProps.data.form_questions?.length }}
          </template>
        </PrimeColumn>
        <PrimeColumn field="actions">
          <template #body="slotProps">
            <div class="flex justify-end">
              <EditForm :form="slotProps.data" />

              <PrimeButton
                v-tooltip.left="'Usuń formularz'"
                text
                icon
                severity="danger"
                @click="confirmDelete(slotProps.data.id)"
              >
                <i class="pi pi-trash" />
              </PrimeButton>
            </div>
          </template>
        </PrimeColumn>

        <template #expansion="slotProps">
          <div class="-mx-4 rounded-2xl border border-gray-2 bg-gray-1 p-3">
            <FormUsersTable :form-users="slotProps.data.form_users" />
          </div>
        </template>
      </PrimeDataTable>
      <PrimePaginator
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        :rows="per_page"
        :totalRecords="forms?.meta.total"
        :alwaysShow="false"
        @page="pageUpdate"
      />
    </template>
    <div v-else>Brak formularzy</div>
  </div>
</template>
