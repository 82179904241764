<script setup lang="ts">
const props = defineProps<{
  campaigns: (NotificationCampaign | NotificationTemplate)[];
  type: 'active' | 'draft' | 'template';
}>();

const { confirmDeleteNotification } = useDeleteNotificationCampaign(props.type);
const { confirmDeleteNotificationTemplate } = useDeleteNotificationTemplate();
const { getSchematById } = useSchemats();
const { expandedRows, expandTable } = useExpandableTable();
</script>

<template>
  <PrimeDataTable
    class="primetable"
    v-model:expandedRows="expandedRows"
    :value="campaigns"
    dataKey="id"
  >
    <PrimeColumn field="designation" header="Nazwa" />

    <PrimeColumn v-if="type === 'active'">
      <template #body="{ data }: { data: NotificationCampaign }">
        <TableExpandButton
          :isExpanded="!!expandedRows[data.id]"
          :disabled="!data.notification_schedules?.length"
          @click="expandTable(data.id, 'notification')"
        >
          {{ data.notification_schedules?.length || 0 }}
        </TableExpandButton>
      </template>
    </PrimeColumn>

    <PrimeColumn field="is_recurring" header="Typ">
      <template
        #body="{ data }: { data: NotificationCampaign | NotificationTemplate }"
      >
        <span class="capitalize">
          {{ data.is_recurring ? 'Cykliczne' : 'Jednorazowe' }}
        </span>
      </template>
    </PrimeColumn>

    <PrimeColumn field="type" header="Sposób wysyłki">
      <template
        #body="{ data }: { data: NotificationCampaign | NotificationTemplate }"
      >
        <span class="capitalize">
          {{ data.type }}
        </span>
      </template>
    </PrimeColumn>

    <PrimeColumn field="schemat" header="Schemat">
      <template
        #body="{ data }: { data: NotificationCampaign | NotificationTemplate }"
      >
        {{ getSchematById(data.schemat_id)?.name || '-' }}
      </template>
    </PrimeColumn>

    <PrimeColumn field="recurring_interval" header="Powtarzaj co">
      <template
        #body="{ data }: { data: NotificationCampaign | NotificationTemplate }"
      >
        {{ formatRecurring(data) || 'Nie' }}
      </template>
    </PrimeColumn>

    <PrimeColumn field="actions" header="">
      <template
        #body="{ data }: { data: NotificationCampaign | NotificationTemplate }"
      >
        <div class="flex">
          <EditNotificationCampaigns :notification="data" :type="type" />

          <PrimeButton
            v-tooltip.left="'Usuń powiadomienie'"
            text
            icon
            severity="warn"
            @click="
              type === 'template'
                ? confirmDeleteNotificationTemplate(data.id)
                : confirmDeleteNotification(data.id)
            "
          >
            <i class="pi pi-trash" />
          </PrimeButton>
        </div>
      </template>
    </PrimeColumn>

    <template #expansion="{ data }: { data: NotificationCampaign }">
      <div
        v-if="type === 'active'"
        class="-mx-4 rounded-2xl border border-gray-2 bg-gray-1 p-3"
      >
        <NotificationCampaignUsersTable
          :schedules="data.notification_schedules"
        />
      </div>
    </template>
  </PrimeDataTable>
</template>
