<script setup lang="ts">
import FormForm from '~/components/FormForm.vue';

const props = defineProps<{
  form: Form;
}>();

const visible = ref(false);

const {
  form: formData,
  errors,
  handleForm,
  resetForm,
} = useEditForm({ form_object: props.form }, () => (visible.value = false));
</script>

<template>
  <div>
    <PrimeButton
      v-tooltip.left="'Edytuj formularz'"
      text
      icon
      severity="secondary"
      @click="visible = true"
    >
      <i class="pi pi-pen-to-square" />
    </PrimeButton>
    <LazyFormForm
      v-if="visible"
      v-model:form="formData"
      v-model:visible="visible"
      :errors="errors"
      :id="props.form"
      @save="handleForm"
    />
  </div>
</template>
