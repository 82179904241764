<script setup lang="ts">
import NotificationFormForm from '@/components/NotificationFormForm.vue';
import NotificationDefaultForm from '@/components/NotificationDefaultForm.vue';
import NotificationVisitForm from '@/components/NotificationVisitForm.vue';

const props = defineProps<{
  notification: NotificationCampaign | NotificationTemplate;
  type: 'active' | 'draft' | 'template';
}>();

const visible = ref(false);

const { formRefs, forms, changeType, fillForm, handleSubmit, isPending } =
  useAddNotificationCampaign(() => {
    visible.value = false;
  });

const { handleEdit, isPending: isEditPending } = useEditNotificationCampaign(
  formRefs,
  forms,
  props.notification,
  props.type,
  () => {
    visible.value = false;
  },
);

watch(
  () => formRefs.value[0],
  () => {
    if (!formRefs.value[0]) return;

    fillForm(props.notification);
  },
);

const FORM_COMPONENTS = {
  default: NotificationDefaultForm,
  form: NotificationFormForm,
  visit: NotificationVisitForm,
};
</script>

<template>
  <PrimeButton
    v-tooltip.left="'Edytuj powiadomienie'"
    text
    icon
    severity="secondary"
    @click="visible = true"
  >
    <i class="pi pi-pen-to-square" />
  </PrimeButton>

  <PrimeDialog
    v-model:visible="visible"
    modal
    header="Edytuj powiadomienie"
    :style="{ width: '684px', 'max-width': '90%' }"
    :closeButtonProps="{
      text: true,
      severity: 'secondary',
      style: 'box-shadow: none; padding: 0;',
    }"
  >
    <PrimeAccordion :value="0">
      <template #collapseicon>
        <div
          class="flex h-9 w-9 items-center justify-center rounded-full border-2 border-solid bg-white"
        >
          <i class="pi pi-angle-up"></i>
        </div>
      </template>
      <template #expandicon>
        <div
          class="flex h-9 w-9 items-center justify-center rounded-full border-2 border-solid bg-white"
        >
          <i class="pi pi-angle-down"></i>
        </div>
      </template>

      <PrimeAccordionPanel
        v-for="(form, index) in forms"
        :key="index"
        :value="index"
        class="mt-2"
      >
        <component :is="FORM_COMPONENTS[form.type]" ref="formRefs">
          <NotificationTypeSelector
            :type="form.type"
            @update:type="changeType($event, index)"
          />
        </component>
      </PrimeAccordionPanel>
    </PrimeAccordion>

    <div class="mt-4 flex justify-between gap-3">
      <PrimeButton
        :disabled="isPending"
        label="Zapisz jako kopię roboczą"
        class="flex-1 font-semibold"
        size="small"
        severity="secondary"
        rounded
        outlined
        @click="handleSubmit('draft')"
      />

      <PrimeButton
        :disabled="isPending"
        label="Zapisz jako szablon"
        class="flex-1 font-semibold"
        size="small"
        severity="secondary"
        rounded
        outlined
        @click="handleSubmit('template')"
      />

      <PrimeButton
        :disabled="isEditPending"
        label="Zapisz"
        class="flex-1 font-semibold"
        size="small"
        rounded
        @click="handleEdit()"
      />
    </div>
  </PrimeDialog>
</template>
