import type { UnwrapRef } from 'vue';

export function useExpandableTable<T = string>() {
  const expandedRows = ref<{ [key: number]: boolean }>({});
  const expandedType = ref<T | null>(null);

  function expandTable(id: number, type: T) {
    if (expandedRows.value[id] && expandedType.value === type) {
      expandedRows.value = {};
      expandedType.value = null;
      return;
    }

    expandedRows.value = {
      [id]: true,
    };

    expandedType.value = type as UnwrapRef<T>;
  }

  return {
    expandedRows,
    expandedType,
    expandTable,
  };
}
